<template>
  <div class="img-page pc">
    <div class="img-page-content">
      <!-- 左侧分类树 -->
      <div class="img-categories">
        <div class="title">{{$t('layout.companyPhotos')}}</div>
        <el-tree class="category-tree" :data="menus" node-key="id" :current-node-key="checkMenu"
                 @current-change="changeType"></el-tree>
      </div>

      <!-- 右侧产品列表 -->
      <div class="img-products">
        <!-- 面包屑导航 -->
        <el-breadcrumb>
          <el-breadcrumb-item>
            <router-link to="/">{{$t('layout.homePage')}}</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('layout.companyPhotos')}}</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 产品列表 每页九条，3*3 -->
        <div id="gallery" class="product-table">
          <div v-for="p in queryResult.records" v-bind:key="p.itemId" class="product">
            <!-- 保持240*320宽高比 -->
            <aspect-ratio class="image" ratio="125">
              <a :href="p.url" data-lightbox="photo-center-photos">
                <img style="height: 100%;width: 100%;object-fit:cover;padding-bottom: 50px;" v-bind:src="$resources.autoTransform(p.url)" />
              </a>
            </aspect-ratio>
            <div class="name">
              {{$i18n.locale === 'zh-CN' ? p.zhName : p.enName}}
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <el-pagination background layout="prev, pager, next" :total="queryResult.total"
                       @current-change="currentPageChange"
                       @prev-click="currentPageChange(productsPagination.current-1)"
                       @next-click="currentPageChange(productsPagination.current+1)">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>

  import AspectRatio from '../../components/AspectRatio';

  export default {
    components: {
      'aspect-ratio': AspectRatio,
    },

    data() {
      return {
        menus: [],
        checkMenu: '',

        photos: [],

        img: {},
        imgDialogVisible: false,

        queryParam: {
          current: 1,
          size: 9,
          total: 0,
          type: '',
        },
        queryResult: {
          records: [],
          total: 1,
          current: 1,
        },
      };
    },

    created() {
      window.page = this;
      this.menus = [{
        id: this.$t('layout.companyHonor'),
        label: this.$t('layout.companyHonor'),
      }, {
        id: this.$t('layout.companyEnvironment'),
        label: this.$t('layout.companyEnvironment'),
      }];
      this.checkMenu = this.$route.query.type;
      this.query();

      lightbox.option({
        'showImageNumberLabel': false,
      })
    },

    watch: {
      $route(newValue, oldValue) {
        if (newValue.query.type !== this.checkMenu) {
          this.checkMenu = newValue.query.type;
          this.query();
        }
      }
    },

    methods: {
      query() {
        const loading = this.$loading();

        const type = this.$route.query.type;
        this.queryParam.type = type === this.$t('layout.companyHonor') ? '企业荣誉' : '园区环境';

        this.$ajax.get('/photos', {params: this.queryParam}).then(res => {
          if (res.code !== 100) {
            return;
          }

          this.queryResult = res.data;
          this.queryParam.total = this.queryResult.total;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }).finally(() => loading.close());
      },

      changeType(a, b, c) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            type: a.label
          }
        }).then(() => {
          this.queryParam = {
            current: 1,
            size: 10,
            total: 0,
            type: '',
          };
          this.queryParam.type = a.label;
          this.query();
        })
      },

      currentPageChange(page) {
        this.productsPagination.current = page;
        const loading = this.$loading();
        this.loadProducts().then(() => loading.close());
      },
    }

  }
</script>



<style lang="scss">
  .img-page.pc {
    background-color: var(--white);


    .img-page-content {
      width: 100%;
      max-width: 1128px;
      margin: 0 auto;

      display: flex;
      justify-content: center;

      padding-top: 100px;
      padding-bottom: 200px;
    }

    .img-categories {
      min-width: 300px;
      padding-left: 50px;

      .title {
        font-size: 34px;
        font-weight: 700;
        color: var(--black);
      }
      .category-tree {
        margin-top: 30px;
        overflow: auto;

        .el-tree-node.is-current {
          .el-tree-node__content {
            color: var(--white);
            background-color: rgb(179, 216, 255);
          }
        }

        .el-tree-node__content {
          height: 40px;
          .el-tree-node__label {
            font-size: 16px;
          }
        }
        .el-tree-node__content:hover {
          color: var(--white);
          background-color: #409EFF;
        }
      }
    }

    .img-products {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      flex: 1;
      max-width: 800px;
      margin-left: 35px;

      .el-breadcrumb {
        height: 48px;
        margin-left: 15px;
        line-height: 60px;
      }

      .product-table {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 15px;

        .product {
          width: 33.3333%;
          padding: 15px 20px;
          position: relative;
          cursor: pointer;

          .image {
            width: 100%;
            &>img {
              width: 100%;
              height: 100%;
            }
          }

          .name {
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            text-align: center;
            color: var(--black);
            background-color: rgb(242,242,242);

            position: absolute;
            bottom: 15px;
            left: 20px;
            right: 20px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .mask {
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 16px;
            color: var(--white);
            background-color: rgba(1,1,1, .4);

            opacity: 0;
            -webkit-transition: all linear .3s;
            -moz-transition: all linear .3s;
            -ms-transition: all linear .3s;
            -o-transition: all linear .3s;
            transition: all linear .3s;

            position: absolute;
            top: 15px;
            right: 20px;
            bottom: 15px;
            left: 20px;
            z-index: 10;
          }
          &:hover .mask {
            opacity: 1;
          }
        }
      }

      .el-pagination {
        margin-top: 50px;
        text-align: center;
      }
    }
  }
</style>
