<template>
    <div>
        <photos-pc v-if="$responsive.is('pc')"></photos-pc>
        <photos-mobile v-if="$responsive.is('mobile')"></photos-mobile>
    </div>
</template>


<script>
    import PhotosPC from './photos-pc';
    import PhotosMobile from './photos-mobile';

    export default {
      components: {
        'photos-pc': PhotosPC,
        'photos-mobile': PhotosMobile,
      },
    }
</script>