<template>
  <div class="img-page mobile">
    <div class="img-page-content">

      <!-- 标题 -->
      <el-breadcrumb class="title">
        <el-breadcrumb-item>
          <router-link to="/">{{$t('layout.homePage')}}</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('layout.companyPhotos')}}</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 菜单 -->
      <ul class="menus reset">
        <li class="menu" @click="selectedMenu = $t('companyHonor')">
          <router-link :to="'/photos/photos?type=' + $t('layout.companyHonor')"
                       :class="'menu-name ' + (selectedMenu === $t('layout.companyHonor') ? 'selected' : '')">
            {{$t('layout.companyHonor')}}
          </router-link>
        </li>
        <li class="menu" @click="selectedMenu = $t('companyEnvironment')">
          <router-link :to="'/photos/photos?type=' + $t('layout.companyEnvironment')"
                       :class="'menu-name ' + (selectedMenu === $t('layout.companyEnvironment') ? 'selected' : '')">
            {{$t('layout.companyEnvironment')}}
          </router-link>
        </li>
      </ul>

      <!-- 右侧产品列表 -->
      <div class="img-products">
        <!-- 产品列表 每页九条，3*3 -->
        <div id="gallery" class="product-table">
          <div v-for="p in queryResult.records" v-bind:key="p.itemId" class="product">
            <!-- 保持240*320宽高比 -->
            <aspect-ratio class="image" ratio="100">
              <a :href="p.url" data-lightbox="photo-center-photos" class="image-image">
                <img v-bind:src="$resources.autoTransform(p.url)" />
              </a>
            </aspect-ratio>
            <div class="name">
              {{$i18n.locale === 'zh-CN' ? p.zhName : p.enName}}
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <el-pagination background layout="prev, pager, next" :total="queryResult.total"
                       @current-change="currentPageChange"
                       @prev-click="currentPageChange(productsPagination.current-1)"
                       @next-click="currentPageChange(productsPagination.current+1)">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>

  import AspectRatio from '../../components/AspectRatio';

  export default {
    components: {
      'aspect-ratio': AspectRatio,
    },

    data() {
      return {
        selectedMenu: '',

        photos: [],

        img: {},
        imgDialogVisible: false,

        queryParam: {
          current: 1,
          size: 9,
          total: 0,
          type: '',
        },
        queryResult: {
          records: [],
          total: 1,
          current: 1,
        },
      };
    },

    created() {
      const allTypes = [
        this.$t('layout.companyHonor'),
        this.$t('layout.companyEnvironment')
      ];
      let queryType = this.$route.query.type;
      if (!queryType || allTypes.findIndex(t => t === queryType) < 0) {
        this.$router.replace({
          path: '/photos/photos?type=' + this.$t('layout.companyHonor')
        });
        return;
      }

      this.selectedMenu = queryType;
      this.query();

      lightbox.option({
        'showImageNumberLabel': false,
      })
    },

    watch: {
      $route(newValue, oldValue) {
        if (this.selectedMenu !== newValue.query.type) {
          this.selectedMenu = newValue.query.type;
          this.query();
        }
      }
    },

    methods: {
      query() {
        const loading = this.$loading();

        const type = this.$route.query.type;
        this.queryParam.type = type === this.$t('layout.companyHonor') ? '企业荣誉' : '园区环境';

        this.$ajax.get('/photos', {params: this.queryParam}).then(res => {
          if (res.code !== 100) {
            return;
          }

          this.queryResult = res.data;
          this.queryParam.total = this.queryResult.total;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }).finally(() => loading.close());
      },

      changeType(a, b, c) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            type: a.label
          }
        }).then(() => {
          this.queryParam = {
            current: 1,
            size: 10,
            total: 0,
            type: '',
          };
          this.queryParam.type = a.label;
          this.query();
        })
      },

      currentPageChange(page) {
        this.productsPagination.current = page;
        const loading = this.$loading();
        this.loadProducts().then(() => loading.close());
      },
    }

  }
</script>



<style lang="scss">
  .img-page.mobile {
    background-color: var(--white);

    .img-page-content {
      width: 100%;
      max-width: 1128px;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      justify-content: center;

      padding-bottom: 20px;

      .el-breadcrumb {
        margin-left: 20px;
        padding: 20px 0;
      }

      .menus {
        width: 100%;

        .menu {
          &:not(:first-child) {
            border-top: 1px solid #f0f0f0;
          }

          .menu-name {
            padding: 0 20px;
            font-size: 16px;
            font-weight: 700;
            line-height: 50px;
            &.selected {
              color: var(--red);
            }
          }
        }
      }

    }

    .img-products {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      flex: 1;
      max-width: 800px;

      .el-breadcrumb {
        height: 48px;
        margin-left: 15px;
        line-height: 60px;
      }

      .product-table {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 15px;

        .product {
          width: 33.3333%;
          padding: 15px 10px;
          position: relative;
          cursor: pointer;

          .image {
            width: 100%;
            & img {
              width: 100%;
              height: 100%;
              object-fit:cover;
            }
          }

          .name {
            height: 20px;
            line-height: 20px;
            font-size: 10px;
            text-align: center;
            color: var(--black);
            background-color: rgb(242,242,242);

            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;

            position: absolute;
            left: 8px;
            right: 8px;
          }

          .mask {
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 16px;
            color: var(--white);
            background-color: rgba(1,1,1, .4);

            opacity: 0;
            -webkit-transition: all linear .3s;
            -moz-transition: all linear .3s;
            -ms-transition: all linear .3s;
            -o-transition: all linear .3s;
            transition: all linear .3s;

            position: absolute;
            top: 15px;
            right: 20px;
            bottom: 15px;
            left: 20px;
            z-index: 10;
          }
          &:hover .mask {
            opacity: 1;
          }
        }
      }

      .el-pagination {
        margin-top: 30px;
        text-align: center;
      }
    }
  }
</style>